import React from "react";
import styled from "styled-components";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import SEO from "../../components/Seo";
import H from "../../components/Headline";
import ContactForm from "../../components/ContactForm";
import { useAlternateLangs } from "../../components/Hreflangs";

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/contact"
);

const StyledMiddle = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0rem 2vmin;
  h1 {
    width: 100%;
  }
  .contacttextbox {
    max-width: 45%;
    margin-right: 5vmin;
  }
  @media all and (max-width: 600px) {
    .contacttextbox {
      max-width: 100%;
    }
  }
`;



const Contact = function (props) {
  return (
    <Layout location={props.location} alternateLangs={alternateLangs}>

      <React.Fragment>
        <SEO
          title="Kontakt"
          description="Kontakt meg for spørsmål om Analytics og SEO-rådgivning. Jeg svarer vanligvis innen 24 timer."
          lang="no"
          alternateLangs={alternateLangs}
        />

        <MainContent maxWidth="1000">
          <StyledMiddle>
            <H as="h1">Kontakt</H>
            <div className="contacttextbox">
              <p>Ta gjerne kontakt med meg for forretningsforespørsler.</p>
              <p>Send meg en melding, så svarer jeg vanligvis innen 24 timer.</p>
            </div>
            <ContactForm autoFocus showHeadline={false} formName="Generelt kontaktskjema" />
          </StyledMiddle>
        </MainContent>
      </React.Fragment>

    </Layout>
  );
};

export default React.memo(Contact);
